// 接口地址
const baseURL = "https://legacy.openatom.cn";
// oAuth认证baseUrl
const baseUrlOauth = 'https://legacy.openatom.cn/auth_services/';
const imgUrl = `${baseURL}/public`;
import axios from 'axios'

function updatedUserInfo() {
    let userInfo = localStorage.getItem('userLogin');
    if(!userInfo) {
        return
    }
    userInfo = JSON.parse(userInfo)
    return new Promise((resolve, reject) => {
        axios.get('/atom_services/client/user/token', {
            token: userInfo.token
        }).then(res => {
            userInfo.user = res.data.data;
            if (localStorage.getItem("userLogin")) {
                localStorage.setItem("userLogin", JSON.stringify(userInfo));
            }
            localStorage.setItem("userLogin", JSON.stringify(userInfo));
            resolve()
        }, err => {
            reject(err)
        })
    })
}
export default {
    baseUrlOauth,
    baseURL,
    imgUrl,
    updatedUserInfo
}