<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "app",
  data() {
    return {
    };
  },
  // mounted() {
  //   this.getGray();
  // },
  components: {},
  methods: {
    getGray() {
      this.axios.get("/atom_services/common/gray").then((res) => {
        // console.log("改变颜色", res);
        if(res.data.data) {
          document.querySelector('body').style.filter = 'grayscale(1)';
        }
      });
    },
  },
  async mounted() {
    this.getGray();
    await this.$global.updatedUserInfo();

    let userLogin = localStorage.getItem("userLogin");
    if (userLogin != undefined) {
      let fun = () => {
        localStorage.setItem("userLogin", userLogin);
        userLogin = JSON.parse(userLogin);
        localStorage.setItem("token", userLogin.token);
        localStorage.setItem("tokenExp", userLogin.tokenExp);
      };

      if (localStorage.getItem("userLogin") == undefined) {
        fun();
        location.reload();
      } else {
        fun();
      }
    }
    // JSON.parse(localStorage.getItem('userLogin')).token // 参数
  },
};
</script>
<style>
.ok body {
  filter: grayscale(1);
}
</style>  