import router from './router';
// 路由拦截
/**
 * 拦截规则
 * 进入管理页面需要登录，待实现
 */
const loginRoutePath = '/signin';
const MUST_LOGIN = 'mustLogin';
router.beforeEach((to, from, next) => {
    // 颜色
    // if(to.name == 'Home') {
    //     document.documentElement.style.filter = 'grayscale(1)'

    // } else {
    //     document.documentElement.style.filter = 'grayscale(0)'
    // }

    // 滚动条清零
    window.scrollTo(0, 0);
    // 1.判断路由是否需要登录, 根据路径 meta.mustLogin
    let mustLogin = false;
    for (let index = 0; index < to.matched.length; index++) {
        const item = to.matched[index];
        if ([MUST_LOGIN] in item.meta) {
            mustLogin = item.meta[MUST_LOGIN];
        }
    }

    // 条件1：判断是否登录
    // 调减：是否添加过路由
    //不绑定手机号
    // if (localStorage.getItem("userLogin")) { // 判断是否登录
    //    next();
    //    return;
    // }
    // 绑定手机号
    if (localStorage.getItem("userLogin")) { // 判断是否登录
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        if(!userLogin.user.mobile) {
             localStorage.clear();
            //  localStorage.clear();
        }
        next();
        return;
     }
    // 条件1: 是否需要登录
    // 条件2: 是否登录过
    if (!mustLogin || localStorage.getItem("userLogin")) {
        next();
    } else {
        // 未登录进入登录页面
        // console.log('未登录，重定向登录页面');
        next({ path: loginRoutePath, query: { redirect: to.fullPath } });
    }
});

