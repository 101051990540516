import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import Cookies from 'js-cookie'
import './assets/icons' // icon
import 'element-ui/lib/theme-chalk/index.css';
// 后台添加
import VueAnimateNumber from 'vue-animate-number'
// import  './assets/scss/global.scss'
import global from './global/global'



Vue.filter('dataFormat', function (originVal) {
  if (originVal == '' || originVal == null) {
    return "";
  } else {
    const dt = new Date(originVal)

    const y = dt.getFullYear()

    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    const d = (dt.getDate() + 1 + '').padStart(2, '0')

    // const hh =(dt.getHours()+'').padStart(2,'0')

    // const mm =(dt.getMinutes()+'').padStart(2,'0')

    // const ss=(dt.getSeconds()+'').padStart(2,'0')

    return `${y}-${m}-${d}`
  }

})
Vue.prototype.$global = global
import axios from 'axios'
import './styles/editTextBoxCss.scss';
import './styles/style.css';
import './permission.js';
import '@/assets/css/font.css'  //字体

import axiosPlugin from './apis/axiosPlugin'

const oAuthConfig = {
  baseURL: global.baseUrlOauth,
  timeout: 60 * 1000, // Timeout
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
}
const oAuthAxios = axios.create(oAuthConfig);
oAuthAxios.interceptors.request.use(
  (config) => {
    if (Cookies.get('oauth')) {
      config.headers = {
        Authorization: Cookies.get('oauth'),
        Authorization_Offical: localStorage.getItem('token'),
      }
    }

    return config
  })



Vue.prototype.axios = axiosPlugin
Vue.prototype.oAuthAxios = oAuthAxios
Vue.use(VueAnimateNumber);
Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')



// router.afterEach( ( to, from, next ) => {
//   setTimeout(()=>{
//           var _hmt = _hmt || [];
//           (function() {
//               //每次执行前，先移除上次插入的代码
//               document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
//               var hm = document.createElement("script");
//               hm.src = "https://hm.baidu.com/hm.js?xxxx";
//               hm.id = "baidu_tj"
//               var s = document.getElementsByTagName("script")[0];
//               s.parentNode.insertBefore(hm, s);
//           })();
//   },0);
// } );
