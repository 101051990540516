import axios from 'axios';
import router from '../router';
import global from '../global/global'
import { Message } from 'element-ui';
axios.defaults.baseURL = global.baseURL + '/'

axios.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('token')//这个是登录时存进本地环境的token
    config.headers = {
      Authorization: token,
    }
    let tokenExp = localStorage.getItem('tokenExp');
    if (tokenExp) {
      let loginTime = localStorage.getItem('loginTime');
      if (new Date().getTime() > (parseInt(loginTime) + parseInt(tokenExp))) {
        // token 过期
        Message.warning({
          message: "登录过期,重新登录"
        });
        localStorage.clear();
        router.replace('/signin')

        return config;
      }
    }

    return config
  })
axios.interceptors.response.use(
  function (response) {
    let code = response.data.code;
    if (code == 10004 || code == 10034 || code == 300008) {
      localStorage.setItem('token', '');
      if (code == 10004)
        Message.warning({
          message: "登录超时，请重新登录"
        });
      if (code == 300008) {
        Message.warning({
          message: "校验失败,重新登录"
        });

        localStorage.clear();
        router.replace('/signin')
      }
    }
    return response;
  },
  function (error) {
    let code = error.response.data.code;
    if (code == 10004 || code == 10034)
      localStorage.setItem('token', '');
    if (code == 10004)
      Message.warning({
        message: "登录超时，请重新登录"
      });
    return Promise.reject(error);
  }
);

export default axios;