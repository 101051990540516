import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: ()=>import("@/views/Layout/Layout.vue"),
    redirect: "/signin",
    children: [
      // 首页
      // {
      //   path: "/",
      //   name: "Home",
      //   component: ()=>import("@/views/Home/Home.vue"),
      // },
      // 开源峰会
      // {
      //   path: "/summit",
      //   name: "Summit",
      //   component: ()=>import("@/views/Summit/Summit.vue"),
      //   meta: {
      //     title: "开源峰会",
      //   },
      // },
      // 个人详情
      {
        path: "/info",
        name: "Info",
        meta: {
          // 是否需要登录
          mustLogin: true,
        },
        // component: Info,
        component: ()=>import("@/views/Info/index.vue"),
      },
      // 新闻动态
      // {
      //   path: "/journalism",
      //   name: "Journalism",
      //   component: ()=>import("@/views/Journalism/Journalism.vue"),
      //   // meta:{
      //   //     title:"新闻动态",
      //   //     banner:"news",
      //   // }
      // },
      // 法务专栏-法律沙龙
      // {
      //   path: "/lawsalonlist",
      //   name: "Lawsalonlist",
      //   component: ()=>import("@/views/Lawsalon/pages/Lawsalonlist/index.vue"),
      // },
      //商标指引
      // {
      //   path: "/trademark",
      //   name: "Trademark",
      //   component: ()=>import("@/views/Trademark/Trademark.vue"),
      //   meta: {
      //     title: "品牌标识下载",
      //     // banner:"law",
      //   },
      // },
      // 开源系列课程详情
      // {
      //   path: "/courseList/:id",
      //   name: "CourseList",
      //   component: ()=>import("@/views/Opensourcecourses/CourseList.vue"),
      // },
      // {
      //   path: "/courseList",
      //   name: "CourseList",
      //   component: ()=>import("@/views/Opensourcecourses/CourseList.vue"),
      // },
      // {
      //   path: "/opensourcecourses",
      //   name: "opensourcecourses",
      //   component: ()=>import("@/views/Opensourcecourses/Opensourcecourses.vue"),
      //   meta: {
      //     title: "法务专栏-开源系列课程",
      //     // banner:"law",
      //   },
      // },
      // {
      //   path: "/legal-IP",
      //   name: "legal-IP",
      //   component: ()=>import("@/views/Opensourcelicensestranslation/Opensourcelicensestranslation.vue"),
      //   meta: {
      //     title: "开源许可证翻译",
      //     // banner:"law",
      //   },
      // },
      // 法务专栏-法律沙龙
      // {
      //   path: "/lawsalon",
      //   name: "lawsalon",
      //   component: ()=>import("@/views/Lawsalon/Lawsalon.vue"),
      //   meta: {
      //     title: "法律沙龙",
      //     // banner:"law",
      //   },
      // },
      // 法务专栏-文档下载
      // {
      //   path: "/legalresources",
      //   name: "Legalresources",
      //   component: ()=>import("@/views/Legalresources/Legalresources.vue"),
      //   meta: {
      //     title: "文档下载",
      //     // banner:"law",
      //   },
      // },
      // {
      //   path: "/contact",
      //   name: "Contact",
      //   component: ()=>import("@/link/Contact.vue"),
      // },
      // {
      //   path: "/atune",
      //   name: "Atune",
      //   component: ()=>import("@/link/Euler/Atune.vue"),
      // },
      // {
      //   path: "/isula",
      //   name: "Isula",
      //   component: ()=>import("@/link/Euler/Isula.vue"),
      // },
      // {
      //   path: "/secgear",
      //   name: "Secgear",
      //   component: ()=>import("@/link/Euler/Secgear.vue"),
      // },
      // {
      //   path: "/stratoVirt",
      //   name: "StratoVirt",
      //   component: ()=>import("@/link/Euler/StratoVirt.vue"),
      // },
      // {
      //   path: "/about",
      //   name: "About",
      //   component: ()=>import("@/views/About/About.vue"),
      //   meta: {
      //     title: "关于我们",
      //     banner: "community",
      //   },
      // },
      // 社区生态
      // {
      //   path: "/community",
      //   name: "Community",
      //   component: ()=>import("@/views/Community/Community.vue"),
      //   meta: {
      //     title: "社区生态",
      //     banner: "community",
      //   },
      // },

      // 社区活动
      // {
      //   path: "/communityactivities",
      //   name: "communityactivities",
      //   component: ()=>import("@/views/CommunityActivities/CommunityActivities.vue"),
      //   meta: {
      //     title: "社区活动",
      //     banner: "community",
      //   },
      // },
      // 视频资料
      // {
      //   path: "/videodata",
      //   name: "videodata",
      //   component: ()=>import("@/views/VideoData/VideoData.vue"),
      //   meta: {
      //     title: "视频资料",
      //     banner: "community",
      //   },
      // },
      // 项目服务
      // {
      //   path: "/project",
      //   name: "Project",
      //   component: ()=>import("@/views/Project/Project.vue"),
      //   meta: {
      //     title: "项目服务",
      //   },
      // },
      // 基金会服务
      // {
      //   path: "/foundation",
      //   name: "Foundation",
      //   component: ()=>import("@/views/Foundation/Foundation.vue"),
      // },
      // 项目详情
      // {
      //   path: "/projectdetails/:id",
      //   name: "ProjectDetails",
      //   component: ()=>import("@/views/ProjectDetails/ProjectDetails.vue"),
      // },
      // 峰会详情
      // {
      //   path: "/summitdetails/:id",
      //   name: "Summitdetails",
      //   component: ()=>import("@/views/Summitdetails/Summitdetails.vue"),
      // },
      // 项目子集
      // {
      //   path: "/projectdetails/subset/:id",
      //   name: "Subset",
      //   component: ()=>import("@/views/ProjectDetails/Subset/Subset.vue"),
      // },
      // 合作伙伴
      // {
      //   path: "/cooperation",
      //   name: "Cooperation",
      //   component: ()=>import("@/views/Cooperation/Cooperation.vue"),
      //   meta: {
      //     title: "合作伙伴",
      //   },
      // },
      // {
      //   path: "/projectdonation",
      //   name: "Projectdonation",
      //   component: ()=>import("@/views/Projectdonation/Projectdonation.vue"),
      //   meta: {
      //     title: "项目捐赠",
      //   },
      // },
      // 合作伙伴-加入我们
      // {
      //   path: "/joinus",
      //   name: "joinus",
      //   component: ()=>import("@/views/JoinUs/JoinUs.vue"),
      //   meta: {
      //     title: "合作伙伴",
      //     // banner:"partner",
      //   },
      // },
      // 新闻详情
      // {
      //   path: "/newsdetail/:id",
      //   name: "Newsdetail",
      //   component: ()=>import("@/views/Newsdetail/Newsdetail.vue"),
      // },
      // {
      //   path: "/preview/:id",
      //   name: "Preview",
      //   component: ()=>import("@/link/Preview.vue"),
      // },
      // {
      //   path: "/salon/:id",
      //   name: "Salon",
      //   component: ()=>import("@/link/Salon.vue"),
      // },
      // 公开信息
      // {
      //   path: "/information",
      //   name: "Information",
      //   component: ()=>import("@/views/Information/Information.vue"),
      // },
      // 活动详情
      // {
      //   path: "/activityinfo/:id",
      //   // path: '/activityinfo',
      //   name: "Activityinfo",
      //   component: ()=>import("@/views/Activityinfo/Activityinfo.vue"),
      // },
      // 沙龙详情
      // {
      //   path: "/saloninfo",
      //   name: "Saloninfo",
      //   component: ()=>import("@/views/Saloninfo/Saloninfo.vue"),
      // },
      // 信息公开详情
      // {
      //   path: "/informationinfo",
      //   name: "Informationinfo",
      //   component: ()=>import("@/views/Informationinfo/Informationinfo.vue"),
      // },
      // 公益项目
      // {
      //   // 校源行简介
      //   path: "/campus",
      //   name: "campus",
      //   component: ()=>import("@/views/Welfare/Campus/Campus.vue"),
      //   meta: {
      //     title: "开放原子校源行简介",
      //   },
      // },
      // {
      //   path: "/kyst",
      //   name: "kyst",
      //   component: ()=>import("@/views/Welfare/Supports/Supports.vue"),
      //   meta: {
      //     title: "开放原子开源社团资助专项",
      //   },
      // },
      // {
      //   path: '/kyst-signup',
      //   name: 'kyst-signup',
      //   component: ()=>import("@/views/Welfare/Supports/Signup.vue"),
      //   meta: {
      //     mustLogin: true,
      //   }
      // },
      // {
      //   path: "/atom-talk",
      //   name: "atom-talk",
      //   component: ()=>import("@/views/Welfare/Forum/Forum.vue"),
      //   meta: {
      //     title: "开放原子源动力课堂资助专项",
      //   },
      // },
      // {
      //   path: '/atom-talk-signup',
      //   name: 'atom-talk-signup',
      //   component: ()=>import("@/views/Welfare/Forum/Signup.vue"),
      //   meta: {
      //     mustLogin: true
      //   },
      // },
      // {
      //   path: "/grants",
      //   name: "grants",
      //   component: ()=>import("@/views/Welfare/Meritocracy/Meritocracy.vue"),
      //   meta: {
      //     title: "开放原子开源助学金资助专项",
      //   },
      // },
      // {
      //   path: "/talents",
      //   name: "talents",
      //   component: ()=>import("@/views/Welfare/Selected/Selected.vue"),
      //   meta: {
      //     title: "开放原子开源英才资助专项",
      //   },
      // },
      // {
      //   path: "/jsggyj",
      //   name: "jsggyj",
      //   component: ()=>import("@/views/Welfare/Research/Research.vue"),
      //   meta: {
      //     title: "开放原子开源技术攻关研究专项",
      //   },
      // },
      // {
      //   path: "/OpenSourceCompetition",
      //   name: "OpenSourceCompetition",
      //   component: ()=>import("@/views/Welfare/OpenSourceCompetition/OpenSourceCompetition.vue"),
      //   meta: {
      //     title: "开放原子全球开源大赛",
      //   },
      // },
    ],
  },

  // 登录
  {
    path: "/signin",
    name: "Signin",
    component: ()=>import("@/views/Signin/Signin.vue"),
  },
  // oAuth登录
  {
    path: "/signin-oAuth",
    name: "SigninOauth",
    component: ()=>import("@/views/Oauth/SigninOauth.vue"),
  },
  //注册
  {
    path: "/register",
    name: "Register",
    component: ()=>import("@/views/Register/Register.vue"),
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: ()=>import("@/views/Forgot/Forgot.vue"),
  },
  // 隐私
  {
    path: "/privacyPolicy",
    name: "PrivacyPolicy",
    component: ()=>import("@/link/privacyPolicy.vue"),
  },
  {
    path: "/terms",
    name: "Terms",
    component: ()=>import("@/link/terms.vue"),
  },
  {
    path: "/brandguide",
    name: "Brandguide",
    component: ()=>import("@/link/Brandguide.vue"),
  },
  {
    path: '/mobile-kyst-signup',
    name: 'mobile-kyst-signup',
    component: ()=>import("@/views/Welfare/Supports/Signup.vue"),
    meta: {
      mustLogin: true,
    }
  },
  {
    path: '/mobile-atom-talk-signup',
    name: 'mobile-atom-talk-signup',
    component: ()=>import("@/views/Welfare/Forum/Signup.vue"),
    meta: {
      mustLogin: true
    },
  },
  {
    path: "/Mentor-program",
    name: "Mentor-program",
    component: ()=>import("@/link/Mentor-program/Mentor-program.vue"),
  },
  {
    path: "/brand",
    name: "Brand",
    component: ()=>import("@/views/Brand/Brand.vue"),
  },
  {
    path: "/constitution",
    name: "Constitution",
    component: ()=>import("@/link/Constitution.vue"),
  },
  // {
  //   path: "/2022Summit/guide",
  //   name: "Prevention",
  //   component: ()=>import("@/link/2022Summit/Guide.vue"),
  // },
  {
    path: "/oauth/authorizeCheck",
    name: "oAuth认证",
    component: ()=>import("@/views/Oauth/OAuthLoading.vue"),
    meta: {
      title: "oAuth认证",
    },
  },
  {
    path: "/oauth/authorize",
    name: "oAuth认证",
    component: ()=>import("@/views/Oauth/OAuthAuthorize.vue"),
    meta: {
      title: "oAuth认证",
    },
  },
    {
      path: '/404',
      component: ()=>import('@/views/error-page/404'),
      hidden: true
  },

  {
      path: '*',
      redirect: '/404',
      // hidden: true
  }
];

const router = new VueRouter({
  // mode: 'history',
  mode: "history",
  routes: routes,
  base: process.env.BASE_URL,
});

export default router;
